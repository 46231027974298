.project-layout{
    display: flex;
    align-items: center;
    justify-content: center;
}
.project-layout-container{
  width: 80%;
}
.tool-box{
    display: flex;
    flex-direction: column;
    background-color: rgb(230, 230, 230);
    box-shadow: 10px;
    align-items: center;
    margin-bottom: 20px;
}